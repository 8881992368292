.grid {
    margin: 20px auto;
    display: grid;
    grid-gap: 40px;
    padding-bottom: 50px;
    grid-template-columns: repeat(auto-fill, 18em);
}

.albumCard {
    opacity: 1;
    position: relative;
    top: auto;
    left: auto;
    /* bring your own prefixes */
    display: block;
    transform: translate(-0%, -0%);
    box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
    border: 3px solid white;
    width: 18em;
}

.albumImg{
    min-width: 100%;
    min-height: 100%;
    max-width: 150%;
    position: absolute;
    top: 0;
    left: 0;
}

.card-title {
    display: block;
    font-size: 1em;
    text-align: center;
    font-weight: bold;
}
