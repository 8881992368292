.image {
    width: 300px;
    height: 200px;
    object-fit: cover;
}

.Chloe {
    object-position: 100% 0;
}

.Aude {
    object-position: 100% 0;
}
.Alice {
    object-position: 100% 0;
}
.Noor {
    object-position: 0 100%;
}
.Dave {
    object-position: 100% 0;
}
.Michael {
    object-position: 100% 0;
}
