/* modal styles */
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.backdrop img {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    display: block;
    transform: translate(-50%, -50%);
    max-width: 80%;
    max-height: 80%;
    box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
    border: 3px solid white;
}

.backdrop-left {
    position: fixed;
    top: 50%;
    left: 5%;
    /* bring your own prefixes */
    display: block;
    font-size: 40px;
    color: black;
}

.backdrop-left:hover {
    color: white;
    cursor: pointer;
}

.backdrop-right {
    position: fixed;
    top: 50%;
    left: 95%;
    /* bring your own prefixes */
    display: block;
    font-size: 40px;
    color: black;
}

.backdrop-right:hover {
    color: white;
    cursor: pointer;
}