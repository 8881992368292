/* modal styles */
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.popUp {
    z-index: 2;
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    display: block;
    transform: translate(-50%, -50%);
    max-width: 80%;
    max-height: 80%;
    box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
    border: 3px solid white;
    background-color: white;
    border-radius: 5px;
}

.popUp h1 {
    text-align: center;
}

.buttons {
    justify-content: center;
    display: flex;
    font-weight: 500;
}

.buttons button {
    border-radius: 10px;
}
.blue {
    border: 3px solid blue;
}
.blue:hover {
    background-color: lightblue;
    color: white;
}
.green {
     border: 3px solid green;
 }
.green:hover {
    background-color: lightgreen;
    color: white;
}
.red {
     border: 3px solid red;
 }
.red:hover {
    background-color: lightpink;
    color: white;
}