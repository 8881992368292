.patchwork-patch__image {
    position: relative;
    background-color: #333333;
    background-size: cover;
    background-position: center center;
}

.patchwork-patch__container:hover .patchwork-patch__image {
    visibility: hidden;
}

.patchwork-patch__container {
    min-height: 154px;
    height: 237px;
    display: list-item;
    text-align: -webkit-match-parent;
    cursor: pointer
}

.grid-stack-item {
    position: relative !important;
    max-width: 100% !important;
    left: 0 !important;
    top: auto !important;
    margin-bottom: 12px;
    display: inline-block;
    vertical-align: top;
}

.grid-stack-item-content {
    margin: 0;
    position: absolute;
    top: 0;
    left: 6px;
    right: 6px;
    bottom: 0;
    width: auto;
    z-index: 0 !important;
    overflow-x: hidden;
    overflow-y: auto;
}

.patchwork-patch-panel{
    position: absolute;
    top: 0;
    overflow: hidden;
    background: #1076bc;
    padding: 0.625rem;
    width: 100%;
    height: 100%;
    color: #FFFFFF;
}
.patchwork-patch__content{
    height: 100%;
}

.patchwork-patch-panel__title{
    font-size: 1.5rem;
    font-weight: 300;
    color: #FFFFFF;
    word-break: break-word;
}

.patchwork-patch-panel__description {
    font-size: 1.125rem;
    white-space: pre-wrap;
    color: #FFFFFF;
    word-break: break-word;
}

.patchwork-patch-panel__price {
    font-size: 1.25rem;
    font-weight: bold;
    position: absolute;
    padding-bottom: 0.625rem;
    padding-left: 0.625rem;
    left: 0;
    bottom: 0;
    line-height: 2.5rem;
}

.patchwork-patch-panel__button {
    background: #FFFFFF;
    color: #333333;
    font-weight: 300;
    font-size: 1rem;
    position: absolute;
    bottom: 0.625rem;
    right: 0.625rem;
    box-shadow: none;
}

.patchwork-patch-panel__soldout{
    background: none;
    color: #FFFFFF;
    border: none;
    outline: 0;
    position: absolute;
    bottom: 0.625rem;
    right: 0.625rem;
    line-height: 2.5rem;
}

.patchwork-patch-panel__quantinty {
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translate(-50%, -50%);
    margin: 0 auto;
}

.message {
    position: relative;
    margin-bottom: 1.5625rem;
    display: block;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid-column;
}

.message__thumbnail {
    text-align: center;
    padding-bottom: 1.5625rem;
    -webkit-flex: 95px 0 0;
    -ms-flex: 95px 0 0;
    flex: 95px 0 0;
}

.message__hold {
    display: flex;
}

.message__content {
    position: relative;
    padding-bottom: 1.25rem;
    min-height: 5.3125rem;
}

.dot {
    height: 75px;
    width: 75px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}

.name {
    font-size: 20px;
    margin-bottom: 0;
    text-align: left;
}

.minus{
    background-color: red;
    display: table-cell;
}

.plus {
    background-color: green;
}

.item {
    display: inline-block;
}

.cart button {
    height: 20px;
    width: 20px;
    text-align: center;
    padding: 0;
    box-shadow: none;
    color: white;
}

.cart {
    display: flex;
    max-width: 90%;
    justify-content: space-between;

}

.cart button:disabled {
    background-color: grey;
}

.message__content p {
    float: left;
}

.funds {
    margin: 50px 0;
}

.comments {
    text-decoration: underline;
}