body {
  margin-bottom: 50px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f8f8e8;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*Upload progress bar*/
/*.progress-bar {*/
/*    height: 5px;*/
/*    background: #ba0000;*/
/*    margin-top: 20px;*/
/*}*/

/* upload form styles */
form {
    margin: 30px auto 10px;
    text-align: center;
}

label input {
    height: 0;
    width: 0;
    opacity: 0;
}

label {
    display: block;
    width: 30px;
    height: 30px;
    border: 1px solid var(--primary);
    border-radius: 50%;
    margin: 10px auto;
    line-height: 30px;
    color: var(--primary);
    font-weight: bold;
    font-size: 24px;
}

label:hover {
    background: var(--primary);
    color: white;
}

.output {
    height: 60px;
    font-size: 0.8rem;
}

.error {
    color: var(--error);
}


.img-grid {
    margin: 20px auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 40px;
    padding-bottom: 50px;
}

@media (max-width: 1000px) {
    .img-grid {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media (max-width: 800px) {
    .img-grid {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (max-width: 500px) {
    .img-grid {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 300px) {
    .img-grid {
        grid-template-columns: 1fr;
    }
}
.img-wrap {
    overflow: hidden;
    height: 0;
    padding: 50% 0;
    /* padding controls height, will always be perfectly square regardless of width */
    position: relative;
    opacity: 0.8;
}

.img-wrap img {
    min-width: 100%;
    min-height: 100%;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
}

.reminder {
    color: #ba0000;
    text-align: center;
}
