.RSVP {
    box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
    margin: 0.5em;
    padding: 1em;

}

.RSVP form {
    margin: auto;
}

.RSVP span {
    height: 2.5em;
    margin: 10px auto;
    font-weight: bold;
    background-color: #00004d;
    color: white;
}

.RSVP label {
    width: auto;
    height: auto;
    margin: auto;
    line-height: normal;
    font-size: 16px;
}

.RSVP label:hover {
    color: black;
}

.RSVPinput {
    margin: 10px auto;
    background-color: #ba0000 !important;
    color: white;
}

.RSVPnumber {
    margin: 10px auto;
}

.RSVP textarea {
    height: 2.5em;
    margin: 10px auto;
}

.check {
    margin: auto;
    padding: 0;
}

@media screen and (min-width: 990px) {
    .rsvpLable {
        margin-top: 1em !important;
    }
}

.thankYou {
    font-size: 2em;
}
