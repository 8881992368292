.navbarCustom {
    background-color: #00004d;
}

.navbar .navbar-brand {
    color: white;
}

.navbar .navbar-brand:hover {
    color: #ba0000;
}

.navbar-light .navbar-nav .nav-link {
    color: white !important;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: #ba0000 !important;
}

.navbar-toggler {
    background-color: white;
}

.navbar-toggler:hover {
    background-color: #ba0000;
}

@media screen and (min-width: 990px) and (max-width: 1400px) {
    .brandText {
        display: none;
    }
}
