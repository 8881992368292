.faq {
    margin-bottom: auto;
    z-index: 1;
    position: relative;
}

.header {
    color: #070707;
    line-height: 3;
    margin-top: 0 !important;
    font-size: 3em;
    margin-bottom: 9px;
    text-align: center;
    text-shadow: 0 0 5px #ffffff;
}

@media (max-width: 600px) {
    .header {
        font-size: 33px;
    }
}

.accordion {
    max-width: 690px;
    width: 98%;
    margin: auto;
    padding: 10px 10px 50px 0;
}
.accordion-flush .accordion-item:last-child {
    border-bottom: 1px solid white;
}

.question {
    font-size: 25px;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 2px;
    display: flex;
    font-weight: normal;
    background: #1a1919;
    padding: 0.75em 1.12em;
    align-items: center;
}

.text {
    max-height: 1190px;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    background: #303030;
    transition: max-height 0.23s cubic-bezier(0.4, 0, 0.2, 1);
    padding: 0.9em 2.1em 0.7em 1.4em;
    user-select: none;
    white-space: pre-wrap;
}

@media (max-width: 550px) {
    .text {
        font-size: 15px;
        line-height: 25px;
    }
}