.tbl-container{
    overflow-y: scroll;
    height: fit-content;
    max-height: 60vh;
}

.table th {
    position: sticky;
    top: 0;
    background-color: #ffffff;
    border-color: inherit;
    border-style: solid;
    border-width: 1px;
}

.vote {
    margin: 2px;
    border: none;
    background-color: transparent;
    padding: 0;
    box-shadow: none;
}

.fa-thumbs-up {
    color: gray;
    font-size: 2em;
}

.userUp {
    color: green;
}

.fa-thumbs-down {
    color: gray;
    font-size: 2em;
}

.userDown {
    color: red;
}

.left {
    float: left;
}

.right {
    float: right;
}
.voteText {
    display: inline-block;
}

.addSongForm{
    padding: 1% !important;
}

.addSongForm input {
    border: 1px solid grey;
    box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}

.addSongButton {
    padding: 1%;
}
.addSongButton button{
    border: 1px solid grey;
    font-weight: bold;
    height: 2.5em;
    border-radius: 5px;
    margin: 0!important;
    padding: 0 1em;
}
