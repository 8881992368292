.title {
    text-align: center;
    text-decoration-line: underline;
}

.center {
    text-align: center;
}

@media (max-width: 800px) {
    .imageSize {
        max-width: 400px;
        max-height: 100%;
    }
}

@media (max-width: 1200px) {
    .imageSize {
        max-width: 600px;
        max-height: 100%;
    }
}

