.countdown-timer {
    font-family: "Oswald", serif;
    font-size: 3.5em;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    animation: color-change 2s infinite;
}


@keyframes color-change {
    0% {
        color: #00004d;
    }
    50% {
        color: #ba0000;
    }
    100% {
        color: #00004d;
    }
}

.countdown-timer span {
    margin-left: 5px;
    margin-right: 5px;
}

.two-numbers {
    width: 2ch;
}
