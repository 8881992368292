.profile {
    width: 50%;
    height: auto;
}
.aymeric {
    width: 100%;
    height: auto;
}

.emma {
    width: 100%;
    height: auto;
}
