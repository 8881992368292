.title {
    text-align: center;
    text-decoration-line: underline;
}

.center {
    text-align: center;
}

.Banner {
    background-color: #6998ff;
}