.container {
    z-index: 1;
    position: relative;
}

#landing-header {
    text-align: center;
    padding-top: 5vh;
}

#landing-header h1 {
    color: #fff;
    text-shadow: 2px 2px 4px #000000;
}

.card-title img {
    margin: 0 5px;
}

button {
    box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
    background: none;
    font: inherit;
    line-height: 1;
    margin: 0.5em;
    padding: 1em 2em;
}

.glow-on-hover {
    width: 220px;
    height: 50px;
    border: none;
    outline: none;
    color: #fff;
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.glow-on-hover:active {
    color: #000
}

.glow-on-hover:active:after {
    background: transparent;
}

.glow-on-hover:hover:before {
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% {
        background-position: 0 0;
    }
    50% {
        background-position: 400% 0;
    }
    100% {
        background-position: 0 0;
    }
}

.signinCard {
    opacity: 0.8;
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    display: block;
    transform: translate(-50%, -50%);
    box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
    border: 3px solid white;
    width: 25em;
}

.card-title {
    display: block;
    font-size: 44px;
    text-align: center;
    font-weight: bold;
}

.signinLable {
    display: block;
    width: 100%;
    height: 30px;
    border-radius: 50%;
    margin: 10px auto;
    line-height: 30px;
    font-weight: bold;
    font-size: 24px;
}

.signinLable:hover {
    background: var(--primary);
    color: black;
}

.help {
    cursor: pointer;
    text-align: center;
    display: block;

}
