.album {
    text-align: center;
}

.arrow {
    margin:1em;
    border:none;
    padding:0.25em 1em 0.25em 3em;
    text-align:right;
    background:
            linear-gradient(120deg, transparent 1em, #ba0000 1.05em , #ba0000 1.5em, transparent 1.45em, transparent 2em, #ba0000 2.05em) top no-repeat,
            linear-gradient(-300deg, transparent 1em, #ba0000 1.05em , #ba0000 1.5em, transparent 1.45em, transparent 2em, #ba0000 2.05em) bottom no-repeat ;
    background-size: 100% 50%;
    color:white;
}