.accommodation {
    overflow: hidden;
}

.center {
    text-align: center;
}

.booked {
    color: red;
    transform: rotate(45deg);
    transform-origin: 75% top;
    font-weight: bolder;
    font-size: 2.5em;
}

.mainBooked {
    color: red;
    transform: rotate(45deg);
    transform-origin: 29% bottom;
    font-weight: bolder;
    font-size: 2.5em;
    text-align: center;
    margin: 0;
}